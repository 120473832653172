import {
  GET_USER_BASIC_INFO,
  GET_USER_BASIC_INFO_SUCCEEDED,
  GET_USER_BASIC_INFO_FAILED,
  EDIT_USER_BASIC_INFO,
  EDIT_USER_BASIC_INFO_SUCCEEDED,
  EDIT_USER_BASIC_INFO_FAILED,
  GET_USER_EDUCATION_INFO,
  GET_USER_EDUCATION_INFO_SUCCEEDED,
  GET_USER_EDUCATION_INFO_FAILED,
  ADD_USER_EDUCATION_INFO,
  ADD_USER_EDUCATION_INFO_SUCCEEDED,
  ADD_USER_EDUCATION_INFO_FAILED,
  DELETE_USER_EDUCATION_INFO,
  DELETE_USER_EDUCATION_INFO_SUCCEEDED,
  DELETE_USER_EDUCATION_INFO_FAILED,
  EDIT_USER_EDUCATION_INFO,
  EDIT_USER_EDUCATION_INFO_SUCCEEDED,
  EDIT_USER_EDUCATION_INFO_FAILED,
  GET_USER_EXPERIENCE_INFO,
  GET_USER_EXPERIENCE_INFO_SUCCEEDED,
  GET_USER_EXPERIENCE_INFO_FAILED,
  ADD_USER_EXPERIENCE_INFO,
  ADD_USER_EXPERIENCE_INFO_SUCCEEDED,
  ADD_USER_EXPERIENCE_INFO_FAILED,
  DELETE_USER_EXPERIENCE_INFO,
  DELETE_USER_EXPERIENCE_INFO_SUCCEEDED,
  DELETE_USER_EXPERIENCE_INFO_FAILED,
  EDIT_USER_EXPERIENCE_INFO,
  EDIT_USER_EXPERIENCE_INFO_SUCCEEDED,
  EDIT_USER_EXPERIENCE_INFO_FAILED,
  GET_USER_SOCIAL_INFO,
  GET_USER_SOCIAL_INFO_SUCCEEDED,
  GET_USER_SOCIAL_INFO_FAILED,
  EDIT_USER_SOCIAL_INFO,
  EDIT_USER_SOCIAL_INFO_SUCCEEDED,
  EDIT_USER_SOCIAL_INFO_FAILED,
  SET_PROFILE_DATA,
  SET_PROFILE_EDIT_DATA,
  SET_PROFILE_ERROR_DATA,
  GET_COURSE_WISHLIST,
  GET_COURSE_WISHLIST_SUCCEEDED,
  GET_COURSE_WISHLIST_FAILED,
} from './index';

export const getUserBasicInfo = () => {
  return {
    type: GET_USER_BASIC_INFO,
    payload: {
      url: 'my-profile/',
      onSuccess: GET_USER_BASIC_INFO_SUCCEEDED,
      onFailure: GET_USER_BASIC_INFO_FAILED,
    },
  };
};

export const editUserBasicInfo = (data = {}) => {
  return {
    type: EDIT_USER_BASIC_INFO,
    payload: {
      data,
      url: `my-profile/`,
      onSuccess: EDIT_USER_BASIC_INFO_SUCCEEDED,
      onFailure: EDIT_USER_BASIC_INFO_FAILED,
    },
  };
};

export const getUserEducationInfo = () => {
  return {
    type: GET_USER_EDUCATION_INFO,
    payload: {
      url: `tutor/education/`,
      onSuccess: GET_USER_EDUCATION_INFO_SUCCEEDED,
      onFailure: GET_USER_EDUCATION_INFO_FAILED,
    },
  };
};

export const addUserEducationInfo = (data = {}) => {
  return {
    type: ADD_USER_EDUCATION_INFO,
    payload: {
      data,
      url: 'tutor/education/',
      onSuccess: ADD_USER_EDUCATION_INFO_SUCCEEDED,
      onFailure: ADD_USER_EDUCATION_INFO_FAILED,
    },
  };
};

export const editUserEducationInfo = (data = {}, id = {}) => {
  return {
    type: EDIT_USER_EDUCATION_INFO,
    payload: {
      data,
      url: `tutor/education/${id}/`,
      onSuccess: EDIT_USER_EDUCATION_INFO_SUCCEEDED,
      onFailure: EDIT_USER_EDUCATION_INFO_FAILED,
    },
  };
};

export const deleteUserEducationInfo = (data = {}) => {
  return {
    type: DELETE_USER_EDUCATION_INFO,
    payload: {
      url: `tutor/education/${data}`,
      onSuccess: DELETE_USER_EDUCATION_INFO_SUCCEEDED,
      onFailure: DELETE_USER_EDUCATION_INFO_FAILED,
    },
  };
};

export const getUserExperienceInfo = () => {
  return {
    type: GET_USER_EXPERIENCE_INFO,
    payload: {
      url: `tutor/job-experience/`,
      onSuccess: GET_USER_EXPERIENCE_INFO_SUCCEEDED,
      onFailure: GET_USER_EXPERIENCE_INFO_FAILED,
    },
  };
};

export const addUserExperienceInfo = (data = {}) => {
  return {
    type: ADD_USER_EXPERIENCE_INFO,
    payload: {
      data,
      url: 'tutor/job-experience/',
      onSuccess: ADD_USER_EXPERIENCE_INFO_SUCCEEDED,
      onFailure: ADD_USER_EXPERIENCE_INFO_FAILED,
    },
  };
};

export const editUserExperienceInfo = (data = {}, id = {}) => {
  return {
    type: EDIT_USER_EXPERIENCE_INFO,
    payload: {
      data,
      url: `tutor/job-experience/${id}/`,
      onSuccess: EDIT_USER_EXPERIENCE_INFO_SUCCEEDED,
      onFailure: EDIT_USER_EXPERIENCE_INFO_FAILED,
    },
  };
};

export const deleteUserExperienceInfo = (data = {}) => {
  return {
    type: DELETE_USER_EXPERIENCE_INFO,
    payload: {
      url: `tutor/job-experience/${data}`,
      onSuccess: DELETE_USER_EXPERIENCE_INFO_SUCCEEDED,
      onFailure: DELETE_USER_EXPERIENCE_INFO_FAILED,
    },
  };
};

export const getUserSocialInfo = () => {
  return {
    type: GET_USER_SOCIAL_INFO,
    payload: {
      url: 'tutor/',
      onSuccess: GET_USER_SOCIAL_INFO_SUCCEEDED,
      onFailure: GET_USER_SOCIAL_INFO_FAILED,
    },
  };
};

export const editUserSocialInfo = (data = {}, id = {}) => {
  return {
    type: EDIT_USER_SOCIAL_INFO,
    payload: {
      data,
      url: `tutor/${id}/`,
      onSuccess: EDIT_USER_SOCIAL_INFO_SUCCEEDED,
      onFailure: EDIT_USER_SOCIAL_INFO_FAILED,
    },
  };
};

export const getCourseWishlist = () => {
      const currency = window.localStorage.getItem('currency');

      return {
        type: GET_COURSE_WISHLIST,
        payload: {
          url: `profile/wishlist/?currency=${currency}`,
          onSuccess: GET_COURSE_WISHLIST_SUCCEEDED,
          onFailure: GET_COURSE_WISHLIST_FAILED,
        },
      };
};

export const getCourseWishlistByPage = (queries = {}) => {
  let query = '';
      const currency = window.localStorage.getItem('currency');

      if (queries) {
        Object.keys(queries).map((each) => {
          query = `${query}&${each}=${queries[each]}`;
        });
      }

      return {
        type: GET_COURSE_WISHLIST,
        payload: {
          url: `profile/wishlist/?${query}&currency=${currency}`,
          onSuccess: GET_COURSE_WISHLIST_SUCCEEDED,
          onFailure: GET_COURSE_WISHLIST_FAILED,
        },
      };
};

export const setProfileData = (data = {}) => {
  return {
    type: SET_PROFILE_DATA,
    payload: {
      data,
    },
  };
};
export const setProfileEditData = (data = {}) => {
  return {
    type: SET_PROFILE_EDIT_DATA,
    payload: {
      data,
    },
  };
};

export const setProfileErrorData = (data = {}) => {
  return {
    type: SET_PROFILE_ERROR_DATA,
    payload: {
      data,
    },
  };
};
